<template>

  <!--begin::Wrapper-->
  <div
    class="p-10 p-lg-15 mx-auto"
  >
    <!--begin::Form-->
    <Form
      class="form w-100"
      id="kt_login_signin_form"
      ref="formLogin"
      @submit="onSubmitLogin"
      :validation-schema="login"
    >
      <!--begin::Heading-->
      <div class="mb-10 text-center">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{$t("Connexion à still'Quiz")}}</h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-bold fs-4">
          {{$t("Vous avez déjà un compte ?")}}
        </div>
        <!--end::Link-->
      </div>
      <!--begin::Heading-->
      
      <!--begin::Actions-->
      <div class="text-center"  v-show="!state.loaderEnabled">
        <!--begin::Submit button-->
        <a
          href="#"
          @click="loginWithAccesstoken()"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-light-primary w-100 mb-5 mt-2"
        >
          <img style="width: 30px" src="https://www.freeiconspng.com/thumbs/linkedin-logo-png/linkedin-logo-3.png">
          <span class="indicator-label"> {{$t("Se connecter via Linkedin")}} </span>
        </a>
        <!--end::Submit button-->
      </div>

      <div class="d-flex align-items-center mb-10"><div class="border-bottom border-gray-300 mw-50 w-100"></div><span class="fw-bold text-gray-400 fs-7 mx-2">{{$t("OU")}}</span><div class="border-bottom border-gray-300 mw-50 w-100"></div></div>

      <div class="fv-row mb-10" v-show="!state.loaderEnabled">
        
        <!--begin::Label-->
        <label class="form-label fs-6 fw-bolder text-dark">{{$t("Identifiant")}}</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          name="username"
          v-model="username"
          autocomplete="off"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="username" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10"  v-show="!state.loaderEnabled">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack mb-2">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6 mb-0"
            >{{$t("Mot de passe")}}</label
          >
          <!--end::Label-->

          <!--begin::Link-->
          <router-link to="/password-reset" class="link-primary fs-6 fw-bolder">
            {{$t("Mot de passe oublié ?")}}
          </router-link>
          <!--end::Link-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          name="password"
          autocomplete="off"
          v-model="password"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <div class="text-center"  v-show="!state.loaderEnabled">
        <!--begin::Submit button-->
        <button
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
        >
          <span class="indicator-label"> {{$t("Se connecter")}} </span>         

          <span class="indicator-progress">
            {{$t("Chargement...")}}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>

      <!--
      <div class="d-flex align-items-center mb-10 mt-5"><div class="border-bottom border-gray-300 mw-50 w-100"></div><span class="fw-bold text-gray-400 fs-7 mx-2">{{$t("OU")}}</span><div class="border-bottom border-gray-300 mw-50 w-100"></div></div>

      <div class="mb-10 text-center">
        <div class="text-gray-400 fw-bold fs-4">
          {{$t("Vous n'avez pas encore de compte ?")}}
        </div>
      </div>
      

      <div class="text-center"  v-show="!state.loaderEnabled">
      
        <router-link to="/sign-up">
           
          <button
            type="submit"
            ref="submitButton"
            id="kt_sign_in_submit"
            class="btn btn-lg btn-primary w-100 mb-5"
          >
            <span class="indicator-label"> {{$t("Créer un compte utilisateur")}} </span>
          </button>
      </router-link>
        
      </div>

    -->

      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n()

    const state = reactive({
      loaderEnabled: true,
    });
    
    const returnBaseUrl = () => {
      return process.env.VUE_APP_BACK_URL+ "/connect";
    }

    let username = "";
    let password = "";
    
    store.dispatch(Actions.LOGOUT);

    window.localStorage.setItem('filterBordereau', "");
    window.localStorage.setItem('currentBord', "");
    window.localStorage.setItem('currentBordType', "");

    const submitButton = ref<HTMLElement | null>(null);

    const formLogin = ref<HTMLFormElement | null>(null);

    


    onMounted(async () => {
      
      state.loaderEnabled = false;

      if( router.currentRoute.value.params.accesstoken) {
        window.localStorage.setItem('accesstoken', router.currentRoute.value.params.accesstoken as any);
        window.localStorage.setItem('emailLinkedin', router.currentRoute.value.params.mail as any);
      }

    });



    // Faire ici un fonction qui vient vérifier si lors de l'ouverture de la page on a un accesstoken dans le localStorage
    // Si c'est le on appel loginAuto avec l'accesstoken et l'email

    // J'ai commencé à réaliser la fonction si dessous

    const loginWithAccesstoken = () => {
      const storedAccessToken = window.localStorage.getItem('accesstoken');
      const storedEmailLinkedin = window.localStorage.getItem('emailLinkedin');
      store.dispatch(Actions.LOGOUT);
      if (storedAccessToken) {
        loginAuto(storedEmailLinkedin, storedAccessToken);
      }
      else{
        window.location.href= returnBaseUrl();
      }
    }



    const loginAuto = (loginStr, password) => {
      const login = {username: loginStr, password: password};
      //console.log("Login", login);
      store.dispatch(Actions.LOGIN, login)
      .then(() => { 
        if (!store.getters.currentUser.uti_x_bloque){
          Swal.fire({
            title: t("Connexion établie !"), 
            text:
              t("Bienvenue") + " " +
              store.getters.currentUser.uti_c_prenom +
              " " +
              store.getters.currentUser.uti_c_nom +
              " " + t("sur votre espace still'Quiz !"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("Accèder à still'Quiz"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
          router.push({ name: "board" });
            })
        }
        else {
          Swal.fire({
          text: t("Votre compte est désactivé, veuillez contacter Stillnetwork afin de pouvoir vous reconnecter."),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("Retourner sur la page de connexion"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
          });
        }
    })
    .catch(() => {
        //console.log("Erreur Login");
        if(!router.currentRoute.value.params.mail) {
        window.location.href= returnBaseUrl();
        }

    });
        

  }

    if(router.currentRoute.value.params.mail) {
      store.dispatch(Actions.LOGOUT);
      loginAuto(router.currentRoute.value.params.mail,router.currentRoute.value.params.accesstoken);
    }

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .email(t("L'identifiant doit être un email valide"))
        .required(t("L'identifiant est obligatoire"))
        .label("username"),
      password: Yup.string()
        .required(t("Le mot de passe est obligatoire"))
        .label("Password"),
    });



    //Form submit function
    const onSubmitLogin = async (values) => {

      store.dispatch(Actions.LOGOUT);
      
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        //console.log(values);
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => { 
            
            if (!store.getters.currentUser.uti_x_bloque){
              Swal.fire({
                title: t("Connexion établie !"), 
                text:
                  t("Bienvenue") + " " +
                  store.getters.currentUser.uti_c_prenom +
                  " " +
                  store.getters.currentUser.uti_c_nom +
                  " " + t("sur votre espace still'Quiz !"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("Accèder à still'Quiz"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                if (router.currentRoute.value.params.redirect) {
                  const stringRec = (router.currentRoute.value.params.redirect) as string;
                  router.push({ name: stringRec });
                } else {

                  store.dispatch(Actions.SET_CURRENT_BORD, {
                    bordSeq: 0,
                    bordType : ''
                  });

                  router.push({ name: "board" });
                }

              });

            } else {

                Swal.fire({
                text: t("Votre compte est désactivé, veuillez contacter Stillnetwork afin de pouvoir vous reconnecter."),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("Retourner sur la page de connexion"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });

            }


          })
          .catch(() => {
            Swal.fire({
              text: t("Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion."),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("Retourner sur la page de connexion"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);

    

    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
      username,
      password,
      returnBaseUrl,
      loginWithAccesstoken
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

function created() {
  throw new Error("Function not implemented.");
}
</script>